import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDeleteTwinMutation } from 'store/api/tools-v1';
import { SubmitHandler, useForm } from 'react-hook-form';
import { StyledPageWrapper } from 'components/fragments/PageWrapper/styled';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { createRoleAccessChecker } from 'utils/userRolesUtils/userRolesUtils';
import { handleRTKQueryError } from 'store/errorState/errorState';
import { setRTKQueryErrorInfo } from 'utils/errorUtils';
import { SidebarMessage } from 'components/fragments/SidebarError/SidebarError';
import { DeleteTwinForm, FormInput } from './DeleteTwinForm';
import ToolSideBar from '../../components/fragments/ToolSidebar/ToolSidebar';
import { roles } from '../../constants';
import { StyledDeleteTwinPresentation } from './styled';

export const DeleteTwinPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentEnvironment = searchParams.get('env') ?? 'qa';
  const paramDeviceId = searchParams.get('deviceId') ?? undefined;
  const [deviceId, setDeviceId] = useState<string | undefined>(paramDeviceId);
  const [deleteTwin, { error: queryError, isLoading, isSuccess }] = useDeleteTwinMutation();

  const { role } = useAppSelector((state) => state.auth.session);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  if (role === roles.Unauthorized || !createRoleAccessChecker(role)(location.pathname)) {
    navigate('/', { replace: true });
  }

  const onSubmit: SubmitHandler<FormInput> = (formData) => {
    deleteTwin(formData.deviceId);
    const oldParams = Object.fromEntries(searchParams);
    const params = { ...oldParams, deviceId: formData.deviceId };
    setSearchParams(params, { replace: true });
    setDeviceId(formData.deviceId);
  };

  useEffect(() => {
    if (queryError) {
      dispatch(handleRTKQueryError(queryError));
    }
  }, [queryError]);

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm<FormInput>({
    defaultValues: {
      deviceId: deviceId ?? '',
    },
  });

  return (
    <>
      <ToolSideBar>
        {currentEnvironment === 'live' ? (
          <SidebarMessage
            message="You cannot use this tool in the chosen environment"
            textColor="red"
          />
        ) : (
          <DeleteTwinForm
            onSubmit={handleSubmit(onSubmit)}
            register={register}
            errors={formErrors}
          />
        )}
      </ToolSideBar>
      <StyledPageWrapper>
        <StyledDeleteTwinPresentation>
          <h1>Delete Twin</h1>
          {isLoading && !queryError && <h4>Deleting twin for {deviceId}...</h4>}
          {queryError && (
            <div>
              <h2>
                {setRTKQueryErrorInfo(queryError)[0]} - {setRTKQueryErrorInfo(queryError)[1]}
              </h2>
            </div>
          )}
          {!(isLoading && queryError) && isSuccess && <h3>Deleted twin successfully!</h3>}
          <hr />
          <h4>Instructions</h4>
          <p>
            To use this tool, choose the environment in the page top panel to which the mower is
            currently pointing.
          </p>
          <p>
            In the form, the device ID for a G3 mower is the nine-digit serial number of the mower,
            followed by a dash,
          </p>
          <p>
            followed by the nine-digit comboard ID. For G4 mowers, just input the nine-digit serial
            number of the mower.
          </p>
          <p>
            <b>NOTE:</b> If the mower is connected when deleting the twin it will be disconnected.
            In most cases the mower will reconnect automatically and recreate a new twin.
          </p>
        </StyledDeleteTwinPresentation>
      </StyledPageWrapper>
    </>
  );
};
